import React from 'react'
import "../NoFound/NoFound.scss"

const NoFound = () => {
    return (
        <div className="noFoundContainer">
            <h1>404 - Page No Found</h1>
        </div>
    )
}

export default NoFound
